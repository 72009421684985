<script>
  export let size = 48;
  $: sizePx = `${size}px`;
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24"
  viewBox="0 -960 960 960"
  width="24"
  style:width={sizePx}
  style:height={sizePx}
  ><path
    d="M400-160h160v-160H400v160ZM160-400h160v-160H160v160Zm240 0h160v-160H400v160Zm240 0h160v-160H640v160Zm0-240h160v-160H640v160ZM320-80v-240H80v-320h480v-240h320v560H640v240H320Z"
  /></svg
>
